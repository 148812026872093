import { keys, path, applySpec, prop, propOr, cond, has, __, T, includes } from 'ramda';

import { NAME, SOURCES } from 'api/consts';
import { ORGANIZATION_KINDS } from 'store/organizations';

import {
  ID_KEY,
  TITLE,
  TAB,
  REFS,
  DESCRIPTION,
  VALUES,
  STATIC_DASHBOARD_TABS,
  STATIC_DASHBOARDS,
  DEFAULT_CUSTOM_DASHBOARD_SETTINGS,
  DEFAULT_OBSAN_DASHBOARD_SETTINGS,
  EPIDEMIOLOGY_DASHBOARD_TABS,
  EPIDEMIOLOGY_DASHBOARDS,
  DEFAULT_EPIDEMIOLOGY_DASHBOARD_SETTINGS,
  OCCUPANCY_LOCATION,
  RJ_PATIENTS_LOCATION,
  ENTRY_AGE,
  OUTPATIENT_EXPENSES,
  OUTPATIENT_NURSES,
  INPATIENT_NURSES,
  INPATIENT_DURATION,
  CONFIG,
  CURADATA_POPULATION,
  CURADATA_POPULATION_GROUP,
  CURADATA_CARE_PROVIDERS_HISTORY,
  RJ_OUTPATIENT_HISTORY,
  RJ_OUTPATIENT_CLIENTS,
  RJ_OUTPATIENT_CLIENTS_HISTORY,
  RJ_OUTPATIENT_AGE_GROUPS,
  RJ_OUTPATIENT_PRIVATE_PUBLIC,
  RJ_OUTPATIENT_CARE_TYPE,
  RJ_OUTPATIENT_CARE_TYPE_AGE,
  SG_MAP_USE_ELDERLY,
  SG_MAP_LOW_CARE_LEVELS,
} from './consts';

const getSection = applySpec({
  type: prop(ID_KEY),
  name: propOr('N/A', TAB),
  question: prop(NAME),
  sources: prop(SOURCES),
  refs: prop(REFS),
  info: prop(DESCRIPTION),
  tabConfig: prop(CONFIG),
});

const extractCustomDashboards = (d, item) => {
  const { [ID_KEY]: type, [TITLE]: title } = item;
  const dashboards = d;
  const section = getSection(item);

  if (dashboards[type]) {
    dashboards[type].sections.push(section);
  }
  dashboards[type] = {
    id: type,
    isCustom: true,
    title,
    sections: [section],
    withSettings: !includes(type, [
      OUTPATIENT_EXPENSES,
      INPATIENT_NURSES,
      OUTPATIENT_NURSES,
      RJ_OUTPATIENT_HISTORY,
      RJ_OUTPATIENT_CLIENTS,
      RJ_OUTPATIENT_CLIENTS_HISTORY,
      RJ_OUTPATIENT_AGE_GROUPS,
      RJ_OUTPATIENT_PRIVATE_PUBLIC,
      RJ_OUTPATIENT_CARE_TYPE,
      RJ_OUTPATIENT_CARE_TYPE_AGE,
      SG_MAP_USE_ELDERLY,
      SG_MAP_LOW_CARE_LEVELS,
    ]),
    withFullScreen: [
      OCCUPANCY_LOCATION,
      RJ_PATIENTS_LOCATION,
      ENTRY_AGE,
      OUTPATIENT_EXPENSES,
      OUTPATIENT_NURSES,
      INPATIENT_NURSES,
      INPATIENT_DURATION,
      SG_MAP_USE_ELDERLY,
      SG_MAP_LOW_CARE_LEVELS,
    ].includes(type),
    [VALUES]: null,
  };

  return dashboards;
};

const extractMultiDashboards = (d, item, isCustom, organizationKind) => {
  const { [ID_KEY]: type, [TITLE]: title } = item;
  const dashboards = d;
  const [kind, subKind] = type.split('/');

  if (!kind) return dashboards;

  const section = getSection(item);

  if (dashboards[kind]) {
    dashboards[kind].sectionsObj = {
      ...dashboards[kind].sectionsObj,
      [subKind]: section,
    };

    return dashboards;
  }

  dashboards[kind] = {
    id: kind,
    ...(isCustom ? { isCustom: true } : { isStatic: true }),
    title,
    ...(isCustom && {
      withSettings:
        kind === CURADATA_POPULATION || kind === CURADATA_POPULATION_GROUP
          ? organizationKind === ORGANIZATION_KINDS.REGION
          : kind !== CURADATA_CARE_PROVIDERS_HISTORY,

      withFullScreen: false,
      [VALUES]: null,
    }),
    ...(subKind
      ? {
          sections: [],
          sectionsObj: {
            [subKind]: section,
          },
        }
      : {
          sections: [section],
        }),
  };

  return dashboards;
};

export const combineDashboards = (data, organizationKind) => {
  let dashboards = {};

  data.forEach((item) => {
    if (!item[ID_KEY]) return;
    if (DEFAULT_CUSTOM_DASHBOARD_SETTINGS[item[ID_KEY]] || DEFAULT_OBSAN_DASHBOARD_SETTINGS[item[ID_KEY]]) {
      dashboards = extractCustomDashboards(dashboards, item);
    }

    if (STATIC_DASHBOARD_TABS.includes(item[ID_KEY])) {
      dashboards = extractMultiDashboards(dashboards, item, false, organizationKind);
    }

    if (EPIDEMIOLOGY_DASHBOARD_TABS.includes(item[ID_KEY])) {
      dashboards = extractMultiDashboards(dashboards, item, true, organizationKind);
    }
  });

  keys(STATIC_DASHBOARDS).forEach((key) => {
    if (!(dashboards[key] && STATIC_DASHBOARDS[key])) return;

    STATIC_DASHBOARDS[key].forEach((sectionKey) => {
      const section = path([key, 'sectionsObj', sectionKey], dashboards);

      if (section) dashboards[key].sections.push(section);
    });

    delete dashboards[key].sectionsObj;
  });

  keys(EPIDEMIOLOGY_DASHBOARDS).forEach((key) => {
    if (!(dashboards[key] && EPIDEMIOLOGY_DASHBOARDS[key])) return;

    EPIDEMIOLOGY_DASHBOARDS[key].forEach((sectionKey) => {
      const section = path([key, 'sectionsObj', sectionKey], dashboards);

      if (section) dashboards[key].sections.push(section);
    });

    delete dashboards[key].sectionsObj;
  });

  return dashboards;
};

export const getDefaultDashboardSettings = (key) => ({
  endpoint: cond([
    [includes(__, [CURADATA_POPULATION, CURADATA_POPULATION_GROUP, CURADATA_CARE_PROVIDERS_HISTORY]), () => 'curadata'],
    [has(__, DEFAULT_OBSAN_DASHBOARD_SETTINGS), () => 'indicators'],
    [has(__, DEFAULT_EPIDEMIOLOGY_DASHBOARD_SETTINGS), () => 'epidemiology'],
    [T, () => 'clearing'],
  ])(key || null),
  allSettings: {
    ...DEFAULT_CUSTOM_DASHBOARD_SETTINGS,
    ...DEFAULT_OBSAN_DASHBOARD_SETTINGS,
    ...DEFAULT_EPIDEMIOLOGY_DASHBOARD_SETTINGS,
  },
});
